import { useState } from 'react';
import copy from 'clipboard-copy';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

interface ChildProps {
  copy: (content: any) => void;
}

interface CopyProps {
  TooltipProps?: Partial<TooltipProps>;
  children: (props: ChildProps) => React.ReactElement<any>;
  showTooltip?: boolean;
}

const delay = 1500;

/**
 * Render prop component that wraps element in a Tooltip and shows "Copied to clipboard!"
 * confirmation message when the copy function is invoked
 */
const CopyToClipboard = (props: CopyProps): React.ReactElement => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onCopy = (content: any) => {
    copy(content);
    setShowTooltip(true);
  };

  const handleOnTooltipClose = () => {
    setShowTooltip(false);
  };

  return (
    <Tooltip
      open={showTooltip}
      title={'Copied to clipboard!'}
      leaveDelay={delay}
      placement="top"
      onClose={handleOnTooltipClose}
      {...(props || {})}
    >
      {props.children({ copy: onCopy }) as React.ReactElement<any>}
    </Tooltip>
  );
};

export default CopyToClipboard;
