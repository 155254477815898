import { useContext, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import styled, { withTheme } from 'styled-components';
import userContext from 'shared/userContext';
import api from 'utils/api';
import Loader from 'components/Loader';
import { ViewForm, Button } from 'components/_styled';
import { headerHeight } from 'components/Header';
import { Theme } from 'shared/_types';

interface Props {
  theme: Theme;
  redirectTo: string;
  backgroundImg?: string;
}

interface LoginResponse {
  token: string;
  role: string;
  email: string;
}

const Wrapper = styled.div<{ backgroundImg?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - ${headerHeight});

  ${({ backgroundImg }) =>
    backgroundImg ? `background: url(${backgroundImg}) no-repeat center center / cover;` : ''}}
`;

export const LoaderWrapper = styled.div`
  margin: 0px -2rem;
  padding: 0.5rem 0;
  height: 4.5rem;
`;

const Login = ({ theme, redirectTo, backgroundImg }: Props): React.ReactElement => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const { login, signUpNewDealers, user } = useContext(userContext);

  const errorAlert = error && <Alert severity="error">{error}</Alert>;

  // verify if user is logged in to redirect and prevent login view
  useEffect(() => {
    if (user) {
      history.push(redirectTo);
    }
  }, [history, redirectTo, user]);

  return (
    <Wrapper backgroundImg={backgroundImg}>
      <ViewForm
        style={{ marginTop: 0 }}
        onSubmit={async (e) => {
          e.preventDefault();
          setError(null);
          setLoading(true);
          try {
            const res = await api.post<LoginResponse>(`/partners/login`, { email, password });
            if (res.token && res.role && res.email) {
              login({
                token: res.token,
                role: res.role,
                email: res.email,
              });
              history.push(redirectTo);
            } else {
              setLoading(false);
              throw new Error(`Response was malformed: ${JSON.stringify(res)}`);
            }
          } catch (err) {
            setLoading(false);
            const error: any = err;
            console.error(error);
            setError(
              error.message ||
                'An error occurred while trying to log in. Please try again shortly or contact TPC if the issue continues.',
            );
          }
        }}
      >
        {theme?.images?.tradeupLogo && (
          <img src={theme.images.tradeupLogo} alt="TradeUP with The Pro's Closet" />
        )}
        <LoaderWrapper>
          {loading && <Loader />}
          {errorAlert}
        </LoaderWrapper>
        <input
          placeholder="Email"
          name="email"
          type="text"
          required={true}
          autoComplete="email"
          onChange={(e: any) => setEmail(e.target.value)}
          onBlur={() => setError(null)}
          data-testid="email"
        />
        <input
          placeholder="Password"
          name="secret"
          type="password"
          required={true}
          autoComplete="current-password"
          onChange={(e: any) => setPassword(e.target.value)}
          onBlur={() => setError(null)}
          data-testid="password"
        />
        <Button type="submit" disabled={loading}>
          login
        </Button>
        <p>
          <Link to="/passwordreset">Forgot Password?</Link>
        </p>
        {signUpNewDealers && (
          <p>
            <Link to="/signup">Sign Up</Link>
          </p>
        )}
      </ViewForm>
    </Wrapper>
  );
};

export default withTheme(Login);
export const path = '/login';
