import { Component } from 'react';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
  padding: 2rem;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}
interface State {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // todo: GA or coralogix FE error logging?
    console.error(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render(): React.ReactNode {
    const { error, errorInfo } = this.state;
    if (error && errorInfo) {
      return (
        <ErrorWrapper>
          <h2>Uh oh, something's not right.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
