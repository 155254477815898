import { DetailWrapper, DetailRow, DataLabel } from './_styled';

export interface SubmissionProp {
  id: number;
  address?: string;
  city?: string;
  state_usa?: string;
  zip?: string;
  dob?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}
interface Props {
  showSellerAddress?: boolean;
  submission: SubmissionProp;
}

const SellerInfo = (props: Props): React.ReactElement => {
  const { showSellerAddress = true, submission } = props;
  return (
    <>
      <h2>Seller Info</h2>
      <DetailWrapper>
        <DetailRow>
          <DataLabel>Submission ID:</DataLabel>
          <span>{submission.id}</span>
        </DetailRow>
        <DetailRow>
          <DataLabel>Name:</DataLabel>
          <span>{`${submission.first_name} ${submission.last_name}`}</span>
        </DetailRow>
        <DetailRow>
          <DataLabel>Email:</DataLabel>
          <span>{submission.email}</span>
        </DetailRow>
        <DetailRow>
          <DataLabel>Phone:</DataLabel>
          <span>{submission.phone || '--'}</span>
        </DetailRow>
        {showSellerAddress && (
          <>
            <DetailRow>
              <DataLabel>Address:</DataLabel>
              <span>{submission.address}</span>
            </DetailRow>
            <DetailRow>
              <DataLabel>City:</DataLabel>
              <span>{submission.city}</span>
            </DetailRow>
            <DetailRow>
              <DataLabel>State:</DataLabel>
              <span>{submission.state_usa}</span>
            </DetailRow>
            <DetailRow>
              <DataLabel>Zip:</DataLabel>
              <span>{submission.zip}</span>
            </DetailRow>
            {submission.dob && (
              <DetailRow>
                <DataLabel>DOB:</DataLabel>
                <span>{new Date(submission.dob).toLocaleString()}</span>
              </DetailRow>
            )}
          </>
        )}
      </DetailWrapper>
    </>
  );
};

export default SellerInfo;
