import styled from 'styled-components';

export const FormSection = styled.div`
  margin-bottom: 1.5rem;
`;

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
  position: relative;
`;
