import { getUserDataFromCookies } from 'shared/userContext';
import { SubmitResponse } from 'shared/components/submission/CustomerForm';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const _dataLayerPush = (event: string, payload: any) => {
  const user = getUserDataFromCookies();
  window.dataLayer.push({
    event,
    ...payload,
    partner: {
      partnerId: parseInt(process.env.REACT_APP_PARTNER_ID ?? '', 10),
      partnerSiteId: parseInt(process.env.REACT_APP_PARTNER_SITE_ID ?? '', 10),
      loggedIn: !!user,
    },
  });
};

const _getPhotoCount = (photos: string[]) => {
  return photos.filter((p) => !!p).length;
};

export const trackPageView = (): void => {
  _dataLayerPush('page_view', { url: window.location.href });
};

export const trackPhotoUpload = ({
  type,
  url,
  photos,
}: {
  type: string;
  url: string;
  photos: string[];
}): void => {
  _dataLayerPush('photo_upload', {
    submission: {
      photoType: type,
      photoUrl: url,
      photoQuantity: _getPhotoCount(photos),
    },
  });
};

export const trackSubmitSuccess = ({ req, res }: { req: any; res: SubmitResponse }): void => {
  const { brand, model, driveSidePhoto, nonDriveSidePhoto, additionalPhotos } = req.item;
  const { submissionId, activationUrl, customerId } = res;
  _dataLayerPush('submit_success', {
    submission: {
      id: submissionId,
      brand,
      model,
      itemQuantity: 1,
      photoQuantity: _getPhotoCount([driveSidePhoto, nonDriveSidePhoto, ...additionalPhotos]),
    },
    user: {
      customerId: parseInt(customerId, 10),
      newCustomer: !!activationUrl,
    },
  });
};
