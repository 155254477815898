import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  max-width: 40rem;
  min-width: 20rem;
  margin: 3rem auto 0;
  width: 100%;
`;

export const FormSection = styled.div`
  margin-bottom: 2.5rem;
`;

export const InlineRow = styled.div<{ rowLength?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ rowLength = 2 }) => rowLength}, 1fr);
  grid-gap: 1rem;
  align-items: center;
`;

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
  position: relative;
`;

export const StateSelectWrapper = styled.div`
  margin-top: 1rem;
`;

export const LabelWrapper = styled.div`
  padding-top: 1rem;
`;

export const Explanation = styled.p`
  margin-top: 0;
  color: #626366;
  font-size: 15px;
`;

export const CharacterCount = styled.span`
  --spacing: 0.4rem; // stay clear of text entered in the row above
  position: absolute;
  bottom: var(--spacing);
  right: var(--spacing);
  color: ${({ theme }) => theme.mediumGray};
`;

export const LoaderWrapper = styled.div`
  padding: 0.5rem 0;
  height: 3rem;
`;

export const ErrorMsg = styled.p`
  color: red;
`;

export const SuccessMsg = styled.p`
  color: ${({ theme }) => theme.primary};
`;

export const CenteredColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
`;

export const DataForm = styled(ContentWrapper).attrs({ as: 'form' })``;

export const SectionHeaders = styled.h3`
  text-transform: uppercase;
  font-size: 24px;
  margin: 0;
`;

export const HeaderUnderline = styled.hr`
  margin: 1.25rem 0 0.88rem;
  border: 0;
  border-top: 4px solid ${({ theme }) => theme.tpcGreen};
  width: 88px;
`;

export const placeholder = css`
  border: 0.75px solid #929497;
  padding: 16px 14px;
  &.dealerFormStyle::placeholder {
    color: #626366;
  }
`;

export const Select = styled.select`
  ${placeholder};
`;

export const Option = styled.option`
  ${placeholder}
`;
