import { useContext, useState } from 'react';
import userContext from 'shared/userContext';
import api from 'utils/api';
import { ViewForm, Button, ErrorMsg } from 'components/_styled';
import { Description } from './_styled';

interface Props {
  errorMsg?: string;
  userEmail?: string;
}

const PasswordResetView = ({ errorMsg, userEmail }: Props): React.ReactElement => {
  const { user } = useContext(userContext);
  const [email, setEmail] = useState<string>(userEmail ?? user?.email ?? '');
  const [error, setError] = useState<string | null>(errorMsg || null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  return (
    <ViewForm
      onSubmit={async (e) => {
        e.preventDefault();
        setError(null);
        setIsSuccess(false);
        try {
          await api.post('/partners/password/requestReset', { email });
          setIsSuccess(true);
        } catch (error) {
          console.error(error);
          setError(error.message);
        }
      }}
    >
      <Description>Please enter your email address to reset your password:</Description>
      <input
        autoComplete="email"
        type="text"
        placeholder="Email"
        pattern="\S+@\S+\.\S+"
        title="A valid email address"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
      />
      <Button type="submit">reset password</Button>
      {error && <ErrorMsg>{error}</ErrorMsg>}
      {isSuccess && (
        <p style={{ marginBottom: 0 }}>
          An email with a link to reset your password has been sent to {email}.
        </p>
      )}
    </ViewForm>
  );
};

export default PasswordResetView;
