import { TextField, TextFieldProps as MuiBaseProps } from '@material-ui/core';

interface CustomTextFieldProps {
  rows?: number | string;
  multiline?: boolean;
  onChange: (...args: any[]) => void;
}
type MuiTextFieldProps = Omit<MuiBaseProps, 'onChange'>;
type TextFieldProps = CustomTextFieldProps & MuiTextFieldProps;

export default function DealerInput(props: TextFieldProps): React.ReactElement {
  const { rows, multiline, onChange, ...muiProps } = props;
  return (
    <TextField
      rows={rows}
      multiline={multiline}
      onChange={onChange}
      name={muiProps.name}
      value={muiProps.value}
      variant={muiProps.variant || 'outlined'}
      type={muiProps.type || 'text'}
      data-testid={muiProps.name}
      {...muiProps}
    />
  );
}
