import styled from 'styled-components';

export const DetailWrapper = styled.div`
  border: 1px solid black;
  padding: 1rem;
  margin-bottom: 3rem;
  background-color: white;
`;

export const DetailRow = styled.div`
  display: flex;
  padding: .5rem;
  min-height: 3rem;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  }
`;

export const TopAlignedDetailRow = styled(DetailRow)`
  align-items: flex-start;
  padding-top: 1rem;
`;

export const DataLabel = styled.span`
  margin-right: 1rem;
  font-weight: bold;
`;