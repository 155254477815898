import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppWrapper from 'components/AppWrapper';
import ErrorBoundary from 'components/ErrorBoundary';
import DealerSubmissionFormView from 'views/DealerSubmissionFormView';
import IFrameSubmissionFormView from 'views/IFrameSubmissionFormView';
import LoginView from 'views/LoginView';
import PasswordResetView from 'views/PasswordResetView';
import SignUpView from 'views/SignUpView';
import SubmissionDetailView from './SubmissionDetailView';
import SubmissionListView from 'views/SubmissionListView';
import SupportView from 'views/SupportView';
import PrivateRoute from 'components/PrivateRoute';
import { baseTheme } from 'shared/_base';
import tradeupLogo from './images/tradeup-logo.svg';
import submissionBackground from './images/submission-background.jpg';
import SnackBar from 'shared/components/Snackbar';
import { Typography } from '@material-ui/core';

const theme = {
  ...baseTheme,
  primary: '#06038D',
  background: '#F4F7F6',
  images: {
    submissionBackground,
    tradeupLogo,
  },
};
const isProgramPaused = process.env.REACT_APP_PROGRAM_PAUSED === 'true';

const headerLinks = [
  {
    text: 'Submissions',
    to: '/submissions',
  },
  {
    text: 'FAQ',
    href: 'https://support.theproscloset.com/hc/en-us/articles/360051133153-TradeUP-Dealer-FAQ',
  },
  {
    text: 'Support',
    to: '/support',
  },
];
ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppWrapper headerLinks={headerLinks}>
          <ErrorBoundary>
            <Switch>
              <Route exact path="/">
                <LoginView redirectTo="/submissions" />
              </Route>
              <Route path="/login">
                <LoginView redirectTo="/submissions" />
              </Route>
              <PrivateRoute exact path="/submissions" component={SubmissionListView} />
              <PrivateRoute
                exact
                path="/submissions/add"
                component={DealerSubmissionFormView}
                tradeupLogo={tradeupLogo}
              />
              <PrivateRoute
                exact
                path="/submissions/:submissionId"
                component={SubmissionDetailView}
              />
              <Route exact path="/support">
                <SupportView />
              </Route>
              <Route exact path="/passwordreset">
                <PasswordResetView />
              </Route>
              <Route exact path="/signup">
                <SignUpView />
              </Route>
              <Route exact path="/customer/form/:dealerToken">
                {/* todo: dummy iframe site to ensure this form works for all cases */}
                <IFrameSubmissionFormView tradeupLogo={tradeupLogo} />
              </Route>
              <Redirect from="*" to="/" />
            </Switch>
          </ErrorBoundary>
        </AppWrapper>
        {isProgramPaused && (
          <SnackBar
            message={
              <>
                <Typography variant="h6">Program Update</Typography>
                <Typography variant="body2">
                  Thanks for your interest! We're hitting the brakes on accepting more bikes right
                  now though.
                </Typography>
                <Typography variant="body2">
                  We believe that bikes are meant to be used. Stay tuned, as we'll be reevaluating
                  things soon.
                </Typography>
              </>
            }
          />
        )}
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);
