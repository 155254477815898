import { withTheme } from 'styled-components';
import { Theme } from 'shared/_types';
import { BaseThemedComponent, BaseSvg as Svg } from 'shared/_base';

interface AdditionalUploadProps {
  theme: Theme;
  style: React.CSSProperties;
}

class AdditionalUpload extends BaseThemedComponent<AdditionalUploadProps> {
  render() {
    const { style, theme } = this.props;
    return (
      <Svg viewBox="0 0 127.49 127.49" style={style}>
        <path
          d="M59.09,58.06l3.4-3.4V67.54a1.25,1.25,0,1,0,2.5,0V54.66l3.4,3.4a1.25,1.25,0,0,0,.89.37,1.21,1.21,0,0,0,.88-.37,1.25,1.25,0,0,0,0-1.77l-5.53-5.53a1.22,1.22,0,0,0-.41-.27,1.21,1.21,0,0,0-.95,0,1,1,0,0,0-.41.27l-5.54,5.53a1.27,1.27,0,0,0,0,1.77A1.25,1.25,0,0,0,59.09,58.06Z"
          fill={theme.primary}
        />
        <path
          d="M74.18,65.17a1.25,1.25,0,0,0-1.25,1.25v8.17H54.55V66.42a1.25,1.25,0,0,0-2.5,0v9.42a1.25,1.25,0,0,0,1.25,1.25H74.18a1.25,1.25,0,0,0,1.25-1.25V66.42A1.25,1.25,0,0,0,74.18,65.17Z"
          fill={theme.primary}
        />
      </Svg>
    );
  }
}

export default withTheme(AdditionalUpload);
