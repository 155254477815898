import { cloneElement, createContext, ReactElement, ReactNode, useContext, useState } from 'react';
import { Dialog, DialogProps } from '@material-ui/core';
import styled from 'styled-components';

// base modal styles are implemented here, for any specific use cases
// you can add more styles within the same component in which the modal is used
export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.6rem 0;
`;

export const CloseModalButton = styled.button`
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #000;
  border: none;
  cursor: pointer;
`;

export const ModalTitle = styled.h3`
  text-align: left;
  font-size: 2rem;
  margin: 0;
  text-transform: uppercase;
  line-height: 2.6rem;
`;

export const ChildrenContainer = styled.div`
  margin: 0.6rem 2.5rem;

  > div > p {
    color: #626366;
  }

  > div > p > span {
    color: #000000;
  }

  > div > p > a {
    color: ${({ theme }) => theme.tpcGreen};
    text-decoration: underline;
  }
`;

export const ModalCodeSection = styled.div`
  padding: 1.25rem;
  color: #626366;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  margin: 1.6rem 0;
`;

export const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.tpcGreen};
  display: inline-block;
  float: right;
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.75rem 2.5rem;
  border: none;
  cursor: pointer;
  margin: 0.6rem 0 1.25rem;

  &:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    background-color: #ccc;
  }
`;

interface ModalContext {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type ModalButtonProps = {
  children: ReactNode;
};

type ModalProps = Omit<DialogProps, 'open'> & {
  label?: string;
  children?: React.ReactNode;
};

const defaultState: ModalContext = {
  isOpen: false,
  setIsOpen: () => {
    undefined;
  },
};

const ModalContext = createContext<ModalContext>(defaultState);

function Modal({ children }: ModalButtonProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return <ModalContext.Provider value={{ isOpen, setIsOpen }}>{children}</ModalContext.Provider>;
}

function ModalDismissButton({ children: child }: ModalButtonProps): React.ReactElement {
  const { setIsOpen } = useContext(ModalContext);
  const childElement = child as ReactElement;

  return cloneElement(childElement, {
    onClick: (...args: any) => {
      setIsOpen(false);
      if (childElement.props.onClick) {
        childElement.props.onClick(...args);
      }
    },
  });
}

function ModalOpenButton({ children: child }: ModalButtonProps): React.ReactElement {
  const { setIsOpen } = useContext(ModalContext);
  const childElement = child as ReactElement;

  return cloneElement(child as ReactElement, {
    onClick: (...args: any) => {
      setIsOpen(true);
      if (childElement.props.onClick) {
        childElement.props.onClick(...args);
      }
    },
  });
}

function ModalContentsBase(props: Omit<DialogProps, 'open'>): React.ReactElement {
  const { isOpen, setIsOpen } = useContext(ModalContext);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      {...props}
      PaperProps={{
        style: {
          maxWidth: '680px',
        },
      }}
    />
  );
}

function ModalContents({ children, ...props }: ModalProps): React.ReactElement {
  return (
    <ModalContentsBase {...props}>
      <ChildrenContainer>{children}</ChildrenContainer>
    </ModalContentsBase>
  );
}

export { Modal, ModalDismissButton, ModalOpenButton, ModalContents };
