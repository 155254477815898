import styled from 'styled-components';

const MessageWrapper = styled.div`
  margin: 1rem auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > span {
    text-align: left;
    margin: auto 2rem;

    > h4 {
      margin-top: 1rem;
      margin-bottom: 0;
    }
    > p {
      margin-top: 0.5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    flex-direction: row;

    > span {
      max-width: 40rem;
      padding: 1rem;
      text-align: left;
    }
  }
`;

const TradeupLogoImg = styled.img`
  max-width: 20rem;
  min-width: 10rem;
  margin: 2rem auto 0;
`;

interface Props {
  tradeupLogo: string;
}

const NoIFrameMessageView = ({ tradeupLogo }: Props): React.ReactElement => {
  return (
    <>
      <TradeupLogoImg src={tradeupLogo} alt="TradeUP with The Pro's Closet" />
      <MessageWrapper>
        <span>
          <h4>Program Paused</h4>
          <p>
            To ensure utmost security, TPC is requesting that all dealer trade ins are initiated by
            your preferred dealer through their Trade Up portal.
          </p>
          <p>Please visit your dealer in-person, to submit your trade.</p>
        </span>
      </MessageWrapper>
    </>
  );
};

export default NoIFrameMessageView;
