const waitToLoad: (file: File) => Promise<HTMLImageElement> = (file) =>
  new Promise((resolve) => {
    const sourceImgObj = new Image();
    sourceImgObj.onload = () => resolve(sourceImgObj);
    sourceImgObj.src = URL.createObjectURL(file);
  });

export const compress: (file: File, quality: number) => Promise<Blob | null> = (file, quality) =>
  new Promise(async (resolve) => {
    const mimeType = 'image/jpeg';
    const sourceImgObj = await waitToLoad(file);
    const maxWidth = 1600;
    const maxHeight = 1600;
    const widthIsBigger = maxWidth > maxHeight;
    let natW = sourceImgObj.naturalWidth;
    let natH = sourceImgObj.naturalHeight;
    const ratio = natH / natW;

    if (widthIsBigger && natW > maxWidth) {
      natW = maxWidth;
      natH = ratio * maxWidth;
    } else if (!widthIsBigger && natH > maxHeight) {
      natW = maxHeight;
      natH = ratio * maxHeight;
    }

    const imgCanvas = document.createElement('canvas');
    imgCanvas.width = natW;
    imgCanvas.height = natH;
    const ctx = imgCanvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(sourceImgObj, 0, 0, natW, natH);
      return imgCanvas.toBlob((blob) => resolve(blob), mimeType, quality / 100);
    } else {
      console.error('2D canvas context not supported');
      return Promise.resolve(null);
    }
  });
