import { ChangeEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface Dealer {
  name: string;
  id: number;
}

interface Discipline {
  discipline: string;
  id: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useForm(formModel: {
  dealers: Dealer[];
  dealerId: string;
  brand: string;
  model: string;
  year: string;
  serial_number: string;
  size: string;
  disciplines: Discipline[];
  disciplineId: string;
  description: string;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;

  preferredShopContact: string;
  shopEmail: string;
}) {
  const [values, setValues] = useState(formModel);
  const { goBack } = useHistory();

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target as HTMLButtonElement;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleReset = (): void => {
    setValues(formModel);
    goBack();
  };

  return {
    values,
    setValues,
    handleInputChange,
    handleReset,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
}));

export function Form(props: { children: any; onSubmit: any }): React.ReactElement {
  const classes = useStyles();
  const { children, onSubmit, ...other } = props;
  return (
    <form className={classes.root} autoComplete="off" onSubmit={onSubmit} {...other}>
      {children}
    </form>
  );
}
