import { Component } from 'react';
import styled from 'styled-components';
import BrandAutoSuggest from 'shared/components/submission/detail/BrandAutoSuggest';
import PhotoUpload from 'shared/components/submission/PhotoUpload';
import { ContentWrapper, DataForm, Button, LinkButton, ResponsiveRow } from 'components/_styled';
import {
  FormSection,
  InlineRow,
  InputWrapper,
  Explanation,
  CharacterCount,
  ErrorMsg,
  CenteredColumn,
} from './_styled';
import Loader from '../../Loader';
import api from 'utils/api';
import pick from 'lodash.pick';
import { trackPhotoUpload, trackSubmitSuccess } from 'shared/utils/analytics';

// todo: move to credstash/appconfig
const customerServiceEmail = 'customerservice@theproscloset.com';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  dealerToken?: string;
}

export const LoaderWrapper = styled.div`
  padding: 0.5rem 0;
  height: 3rem;
`;

class State {
  // form fields
  brand = '';
  model = '';
  description = '';
  driveSidePhoto = '';
  nonDriveSidePhoto = '';
  additionalPhotos: string[] = ['', '', '']; // empty strings are stripped before POST

  // seller info
  firstName = '';
  lastName = '';
  email = '';
  zip = '';

  // form state
  submitSuccess = false;
  submitError = false;
  loading = false;
  activationUrl: string | undefined = undefined;

  public constructor(init?: Partial<State>) {
    Object.assign(this, init);
  }
}

export interface SubmitResponse {
  submissionId: number;
  customerId: string;
  activationUrl?: string;
}

class CustomerForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = new State();
    this.trackPhoto = this.trackPhoto.bind(this);
  }

  handleReset = (): void => {
    const newState = new State();
    this.setState({ ...newState });
  };

  trackPhoto = ({ type, url }: { type: string; url: string }): void => {
    const photos = [
      this.state.driveSidePhoto,
      this.state.nonDriveSidePhoto,
      ...this.state.additionalPhotos,
    ];
    trackPhotoUpload({ type, url, photos });
  };

  render(): React.ReactElement {
    if (this.state.submitSuccess) {
      const submitButton = (
        <Button onClick={this.handleReset} style={{ margin: '0 auto' }}>
          submit again
        </Button>
      );
      let buttonsRow;
      if (this.state.activationUrl) {
        buttonsRow = (
          <InlineRow>
            <LinkButton
              href={this.state.activationUrl}
              style={{ margin: '0 auto', padding: '.75rem 1.5rem' }}
              target="_blank"
            >
              activate account
            </LinkButton>
            {submitButton}
          </InlineRow>
        );
      } else {
        buttonsRow = submitButton;
      }

      return (
        <ContentWrapper style={{ fontSize: '18px' }}>
          <p>{`Thank you for submitting your ${this.state.brand} ${this.state.model}!`}</p>
          <p>We have emailed you a confirmation, and our buyers are reviewing your submission.</p>
          {this.state.activationUrl ? (
            <p>
              In order to accept an offer, you will need to activate your account with The Pro's
              Closet.
            </p>
          ) : (
            <p>If your bike qualifies, you'll receive an offer in 24 hours.</p>
          )}
          <br />
          {buttonsRow}
        </ContentWrapper>
      );
    }

    const placeholderModel =
      process.env.REACT_APP_PARTNER_SITE_MODEL || 'Tallboy, SB130, Domane...';
    return (
      <DataForm
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        onSubmit={async (e) => {
          e.preventDefault();
          this.setState({
            loading: true,
            submitSuccess: false,
            submitError: false,
          });
          try {
            const url = this.props?.dealerToken
              ? `/partners/customer/submission/${this.props.dealerToken}`
              : '/partners/submission';
            const data = {
              item: {
                ...pick(this.state, [
                  'brand',
                  'model',
                  'description',
                  'driveSidePhoto',
                  'nonDriveSidePhoto',
                ]),
                additionalPhotos: this.state.additionalPhotos.filter((s) => Boolean(s)),
              },
              submission: {
                ...pick(this.state, ['firstName', 'lastName', 'email', 'zip']),
              },
            };
            const result = await api.post<SubmitResponse>(url, data);
            this.setState(
              { submitSuccess: true, activationUrl: result.activationUrl, loading: false },
              () => trackSubmitSuccess({ req: data, res: result }),
            );
          } catch (error) {
            console.error(error);
            this.setState({ submitError: true, loading: false });
          }
        }}
      >
        <FormSection>
          <h3>Bike Info</h3>
          <InputWrapper>
            <label htmlFor="brand">Brand</label>
            <BrandAutoSuggest
              value={this.state.brand}
              onChange={(brand) => this.setState({ brand })}
              id="brand"
              required={true}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="model">Model</label>
            <input
              type="text"
              id="model"
              placeholder={`e.g. ${placeholderModel}`}
              onChange={({ target: { value } }) => this.setState({ model: value })}
              required={true}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              placeholder="Enter a detailed description of the condition of the bike and any upgrades."
              maxLength={250}
              rows={6}
              value={this.state.description}
              style={{ resize: 'vertical' }}
              onChange={({ target: { value } }) => this.setState({ description: value })}
            />
            <CharacterCount>{250 - this.state.description.length} characters left</CharacterCount>
          </InputWrapper>
        </FormSection>
        <FormSection>
          <h3>Photo Upload</h3>
          <Explanation>
            Please take at least two photos of your bike that are well lit and in focus.
          </Explanation>
          <InlineRow>
            <InputWrapper>
              <span>
                <label htmlFor="driveSideUpload">Drive side</label>
                <Explanation>Side with gears</Explanation>
              </span>
              <PhotoUpload
                alt="drive side photo"
                type="driveSide"
                dealer={false}
                id="driveSideUpload"
                photoUrl={this.state.driveSidePhoto}
                clearImage={() => this.setState({ driveSidePhoto: '' })} // todo: can we delete from s3 if cleared?
                onUploadSuccess={(url: string) =>
                  this.setState({ driveSidePhoto: url }, () =>
                    this.trackPhoto({ type: 'driveSidePhoto', url }),
                  )
                }
                required={true}
              />
            </InputWrapper>
            <InputWrapper>
              <div>
                <label htmlFor="nonDriveSideUpload">Non-drive side</label>
                <Explanation>Side without gears</Explanation>
              </div>
              <PhotoUpload
                alt="non-drive side photo"
                type="nonDriveSide"
                dealer={false}
                id="nonDriveSideUpload"
                photoUrl={this.state.nonDriveSidePhoto}
                clearImage={() => this.setState({ nonDriveSidePhoto: '' })} // todo: can we delete from s3 if cleared?
                onUploadSuccess={(url: string) =>
                  this.setState({ nonDriveSidePhoto: url }, () =>
                    this.trackPhoto({ type: 'nonDriveSidePhoto', url }),
                  )
                }
                required={true}
              />
            </InputWrapper>
          </InlineRow>
          <InputWrapper>
            <div>
              <label>Additional photos</label>
              <Explanation>
                If you have details, upgrades, or any damage to show our experts upload up to three
                additional close-ups of your bike here.
              </Explanation>
            </div>
            <InlineRow rowLength={3}>
              {[0, 1, 2].map((idx) => (
                <PhotoUpload
                  key={idx}
                  alt="additional photo"
                  type="additional"
                  dealer={false}
                  id={`additionalPhoto${idx}`}
                  photoUrl={this.state.additionalPhotos[idx]}
                  clearImage={() =>
                    this.setState((oldState) => {
                      const additionalPhotos = oldState.additionalPhotos;
                      additionalPhotos[idx] = '';
                      return { ...oldState, additionalPhotos };
                    })
                  }
                  onUploadSuccess={(url: string) =>
                    this.setState(
                      (oldState) => {
                        const additionalPhotos = oldState.additionalPhotos;
                        additionalPhotos[idx] = url;
                        return { ...oldState, additionalPhotos };
                      },
                      () => this.trackPhoto({ type: 'additionalPhoto', url }),
                    )
                  }
                />
              ))}
            </InlineRow>
          </InputWrapper>
        </FormSection>
        <FormSection>
          <div style={{ marginBottom: '1rem' }}>
            <h3 style={{ display: 'inline' }}>Seller Info</h3>&ensp;(bike owner)
          </div>
          <ResponsiveRow>
            <InputWrapper>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                placeholder="e.g. Taylor"
                onChange={({ target: { value } }) => this.setState({ firstName: value })}
                required={true}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                placeholder="e.g. Smith"
                onChange={({ target: { value } }) => this.setState({ lastName: value })}
                required={true}
              />
            </InputWrapper>
          </ResponsiveRow>
          <InputWrapper>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="e.g. taylorsmith@gmail.com"
              pattern="\S+@\S+\.\S+"
              title="A valid email address"
              onChange={({ target: { value } }) => this.setState({ email: value })}
              required={true}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="zip">Zip code</label>
            <input
              type="text"
              id="zip"
              placeholder="e.g. 90210"
              minLength={5}
              pattern="^[0-9]{5}(-[0-9]{4})?$"
              title="A valid ZIP code"
              onChange={({ target: { value } }) => this.setState({ zip: value })}
              required={true}
            />
          </InputWrapper>
        </FormSection>
        <CenteredColumn>
          <Button disabled={this.state.loading}>submit</Button>
          <LoaderWrapper>
            {this.state.loading && <Loader />}
            {this.state.submitError && (
              <ErrorMsg>
                There was an error submitting your bike. Please verify that all your information is
                correct and try again or contact{' '}
                <a href={`mailto:${customerServiceEmail}`}>{customerServiceEmail}</a>
              </ErrorMsg>
            )}
          </LoaderWrapper>
        </CenteredColumn>
      </DataForm>
    );
  }
}

export default CustomerForm;
