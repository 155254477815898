import { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// these props are defined by material-table's internal data structure
export interface Props {
  onFilterChanged: (rowId: string, value: any) => void;
  columnDef: any;
}

const FilterComponent = ({ onFilterChanged, columnDef }: Props): React.ReactElement => {
  const [selected, setSelected] = useState(columnDef?.defaultFilter ?? null);

  const handleChange = (e: any) => {
    const val = e.target.value;
    setSelected(val);
    onFilterChanged(columnDef.tableData.id, val);
  };

  return (
    <Select
      data-testid="filter-select"
      value={selected || ''}
      onChange={handleChange}
      style={{ width: '100%' }}
    >
      <MenuItem value="" style={{ color: 'silver' }}>
        -- clear --
      </MenuItem>
      {Object.entries(columnDef.lookup).map(([key, value]: [string, any]) => (
        <MenuItem value={key} key={key}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FilterComponent;
