import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'utils/api';

import RequestResetForm from 'components/passwordReset/RequestResetForm';
import ResetForm from 'components/passwordReset/ResetForm';
import Loader from 'components/Loader';

const PasswordResetView = (): React.ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token: string | null = searchParams.get('q') || null;
  const email: string | null = searchParams.get('e') || null;

  useEffect(() => {
    if (token) {
      const checkTokenValidity = async () => {
        try {
          const isValid = await api.get<boolean>(`/partners/password/validToken?q=${token}`);
          setIsTokenValid(isValid);
        } catch (error) {
          console.log(error);
          setError('Your token is expired or invalid, please reset your password again.');
        }
      };
      checkTokenValidity();
    }
  }, [token]);

  if (!token || error) {
    return <RequestResetForm errorMsg={error ?? undefined} userEmail={email ?? undefined} />;
  }

  if (isTokenValid) {
    return <ResetForm token={token} />;
  } else {
    return <Loader />;
  }
};

export default PasswordResetView;
