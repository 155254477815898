import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiBaseSelectProps,
  MenuItem,
} from '@material-ui/core';

interface CustomSelectProps {
  options: any[];
  onChange: (...args: any[]) => void;
}
type MuiSelectProps = Omit<MuiBaseSelectProps, 'onChange'>;
type SelectProps = CustomSelectProps & MuiSelectProps;

export default function DealerSelect(props: SelectProps): React.ReactElement {
  const { onChange, options, ...muiProps } = props;

  return (
    <FormControl required variant={muiProps.variant || 'outlined'} disabled={options.length === 1}>
      <InputLabel>{muiProps.label}</InputLabel>
      <MuiSelect
        onChange={onChange}
        label={muiProps.label}
        name={muiProps.name}
        value={muiProps.value}
        data-testid={muiProps.name}
        {...muiProps}
      >
        {options.map((item: { id: string; discipline?: string; name?: string }) => (
          <MenuItem key={item.id} value={item.id}>
            {item.discipline ?? item.name}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
