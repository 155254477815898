import { Fragment } from 'react';
import styled from 'styled-components';
import { ResponsiveRow } from 'components/_styled';
import { DetailWrapper, DetailRow, TopAlignedDetailRow, DataLabel } from './_styled'; // detail
import { hasDealerFunctionality } from 'shared/userContext';

const statusDisplayStyle = `
  line-height: 1rem;
  padding: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 1px 1px 0 rgba(0,0,0,.2);
`;

const ColoredSubmissionStatus = styled.span<{ status: string }>`
  ${statusDisplayStyle}
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'completed':
        return theme.tpcGreen;
      case 'declined(user)':
      case 'declined(buyer)':
      case 'expired':
        return theme.tpcRed;
      default:
        return theme.darkGray;
    }
  }};
`;

const ColoredDOStatus = styled.span<{ verified: boolean }>`
  ${statusDisplayStyle}
  background-color: ${({ theme, verified }) => (verified ? theme.tpcGreen : theme.darkGray)};
`;

const MetaText = styled.div`
  color: ${({ theme }) => theme.mediumGray};
`;

export interface SubmissionProp {
  state: string;
  verified: boolean;
  partner_voucher_price: number;
  trade_in_price: number;
  created_at: string;
  Notes: {
    entry: string;
    type: string;
    created_at: string;
  }[];
}
interface Props {
  submission: SubmissionProp;
}

const SubmissionStatus = (props: Props): React.ReactElement => {
  const offerPrice = props.submission.partner_voucher_price;
  const tradeInPrice = props.submission.trade_in_price;

  return (
    <>
      <h2>Submission Status</h2>
      <DetailWrapper>
        <ResponsiveRow>
          <DetailRow>
            <DataLabel>Offer Status:</DataLabel>
            <ColoredSubmissionStatus status={props.submission.state}>
              {props.submission.state}
            </ColoredSubmissionStatus>
          </DetailRow>
          <DetailRow>
            <DataLabel>Declaration of Ownership:</DataLabel>
            <ColoredDOStatus verified={props.submission.verified}>
              {props.submission.verified ? 'signed' : 'unsigned'}
            </ColoredDOStatus>
          </DetailRow>
        </ResponsiveRow>
        {hasDealerFunctionality ? (
          <DetailRow>
            <DataLabel>Offer Amount:</DataLabel>
            <span>{tradeInPrice ? `$${props.submission.trade_in_price}` : '--'}</span>
          </DetailRow>
        ) : (
          <DetailRow>
            <DataLabel>Offer Price:</DataLabel>
            <span>{offerPrice ? `$${props.submission.partner_voucher_price}` : '--'}</span>
          </DetailRow>
        )}
        <DetailRow>
          <DataLabel>Submission Date:</DataLabel>
          <span>{new Date(props.submission.created_at).toLocaleString()}</span>
        </DetailRow>
        <TopAlignedDetailRow>
          <DataLabel>Notes:</DataLabel>
          <div>
            {props.submission.Notes.length
              ? props.submission.Notes.map((n: any) => (
                  <Fragment key={n.created_at}>
                    <MetaText>{new Date(n.created_at).toLocaleDateString()}</MetaText>
                    <p style={{ marginTop: 0 }}>
                      <span style={{ fontWeight: 'bold' }}>{n.type}</span>: {n.entry}
                    </p>
                  </Fragment>
                ))
              : '--'}
          </div>
        </TopAlignedDetailRow>
      </DetailWrapper>
    </>
  );
};

export default SubmissionStatus;
