import { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, withStyles } from '@material-ui/core';
import api from 'utils/api';

// todo: backport this into SYB app

// the documentation on this is atrocious, just look at the code + StackOverflow examples in the future
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Input/Input.js
const StyledAutocomplete = withStyles({
  input: {
    padding: '1rem .25rem !important',
    boxSizing: 'border-box',
    '&:focus': { outline: '' },
    border: '1px solid black',
  },
})(Autocomplete);

interface Brand {
  name: string;
}

interface Props {
  id: string;
  value: string;
  required?: boolean;
  onChange: (val: any) => void;
}

// todo: update to use public DT /brands endpoint along with TP-2112
const BrandAutoSuggest = ({ id, value, required = false, onChange }: Props): React.ReactElement => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchBrands = async () => {
      const url = '/trade-up/syb/brands';
      try {
        const brands = await api.get<Brand[]>(url);
        setBrands(brands);
      } catch (error) {
        // don't break on error because user can still enter brand manually
        console.error(error);
      }
    };
    fetchBrands();
  }, []);

  const placeholderBrand =
    process.env.REACT_APP_PARTNER_SITE_BRAND || 'Specialized, Yeti, Santa Cruz...';

  return (
    <StyledAutocomplete
      fullWidth
      freeSolo // allows entry of non-suggested values
      disableClearable
      id={id}
      open={isOpen}
      onOpen={() => (value.length > 0 ? setIsOpen(true) : null)}
      onClose={() => setIsOpen(false)}
      onInputChange={(_, newVal) => {
        setIsOpen(newVal.length > 0);
        onChange(newVal);
      }}
      options={brands.map((b) => b.name)}
      renderInput={(params: any) => (
        <TextField
          {...params}
          placeholder={`e.g. ${placeholderBrand}`}
          required={required}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter' && e.current.value) onChange(e.target.value);
          }}
        />
      )}
    />
  );
};

export default BrandAutoSuggest;
