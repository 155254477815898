import styled from 'styled-components';
import { BaseThemedComponent } from 'shared/_base';
import { Theme } from 'shared/_types';

const ProgressWrapper = styled.span`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;
`;

const ProgressBarOuter = styled.div`
  background: transparent;
  height: inherit;
`;

const ProgressBarInner = styled.div<{ theme: Theme; width: number }>`
  transition: width 0.8s;
  height: inherit;
  background: ${({ theme }) => theme.primary};
  width: ${({ width }) => `${width}%`};
`;

class ProgressBar extends BaseThemedComponent<{ progress: number }> {
  render(): React.ReactElement {
    return (
      <ProgressWrapper>
        <ProgressBarOuter>
          <ProgressBarInner width={this.props.progress} />
        </ProgressBarOuter>
      </ProgressWrapper>
    );
  }
}

export default ProgressBar;
