import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ViewForm, Button, ErrorMsg, SuccessMsg } from 'components/_styled';
import Loader from 'components/Loader';
import styled, { withTheme } from 'styled-components';
import { Theme } from 'shared/_types';
import { FormSection, InputWrapper } from './_styled';
import {
  CloseModalButton,
  Modal,
  ModalButton,
  ModalContents,
  ModalDismissButton,
  ModalHeader,
  ModalOpenButton,
  ModalTitle,
} from 'components/modal/Modal';
import options from 'utils/stateOptions';
import api from 'utils/api';

export const SignUpFormWrapper = styled.div`
  max-width: 40rem;
  min-width: 20rem;
  margin: 3rem auto;
  width: 100%;
  padding: 0.5rem 1rem 3rem;

  @media (min-width: ${({ theme }) => theme.breakpointSmall}) {
    padding: 1rem 2rem 3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    padding: 2rem 4rem 3rem;
  }
`;

export const LoaderWrapper = styled.div`
  padding-top: 0.5rem;
  height: 3rem;
`;

interface Props {
  theme: Theme;
}

interface ListAdminAccounts {
  id: string;
  name: string;
}
export interface SubmitResponse {
  partnerId: string;
  roleId: string;
}

enum PartnerRoles {
  'admin' = 1,
  'dealer' = 2,
}

const initialState = {
  name: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  password: '',
  confirmPassword: '',
  affiliation_id: '',
  website_url: '',
};

const validStyle = { color: '#00c160' };
const invalidStyle = { color: '#000', fontWeight: 'bold' };
export const contactEmail = 'tradeup@theproscloset.com';

const SignUpForm = ({ theme }: Props): React.ReactElement => {
  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [match, setMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [values, setValues] = useState(initialState);
  const [accounts, setAccounts] = useState<ListAdminAccounts[]>([]);
  const { push } = useHistory();

  const validatePassword = useCallback(() => {
    const value = values.password;
    if (value === '') {
      return false;
    }

    if (value.length >= 8) {
      // check length
      setValidLength(true);
    } else {
      setValidLength(false);
    }

    if (/\d/.test(value)) {
      // check has number
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }

    if (/\W|_/g.test(value)) {
      // check has special char
      setSpecialChar(true);
    } else {
      setSpecialChar(false);
    }
    return null;
  }, [values.password]);

  const passwordMatch = useCallback(() => {
    const value = values.confirmPassword;
    if (value === '') {
      return false;
    }
    const { password, confirmPassword } = values;

    if (password === confirmPassword) {
      setMatch(true);
    } else {
      setMatch(false);
    }
    return null;
  }, [values]);

  useEffect(() => {
    const getListAdminAccounts = async () => {
      const result = await api.get<ListAdminAccounts[]>(`/partners`);
      setAccounts(result);
    };
    getListAdminAccounts();
  }, []);

  useEffect(() => {
    validatePassword();
    passwordMatch();
  }, [values.password, values.confirmPassword, validatePassword, passwordMatch]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const loginRedirect = () => {
    push('/');
  };

  return (
    <SignUpFormWrapper>
      <ViewForm
        style={{ marginTop: 0, paddingTop: 0 }}
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        onSubmit={async (e) => {
          e.preventDefault();
          setError(null);
          setLoading(true);
          try {
            const url = '/partners/signup';
            const data = {
              ...values,
              partnerId: process.env.REACT_APP_PARTNER_ID,
              roleId: PartnerRoles.dealer,
            };
            await api.post<SubmitResponse>(url, data);
            setLoading(false);
            setSuccess('Sign up successful');
          } catch (err) {
            const error: any = err;
            console.error(error);
            setError(error.message);
            setLoading(false);
          }
        }}
      >
        {theme?.images?.tradeupLogo && (
          <img
            src={theme.images.tradeupLogo}
            alt="TradeUP with The Pro's Closet"
            style={{ marginBottom: '2rem' }}
          />
        )}
        <FormSection>
          <InputWrapper>
            <label htmlFor="name">Dealer Name</label>
            <input
              name="name"
              id="name"
              type="text"
              placeholder="The Pro's Closet"
              onChange={handleChange}
              required
              data-testid="name"
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="email">Dealer Email</label>
            <input
              name="email"
              id="email"
              type="email"
              placeholder="bikeshop@shop.com"
              onChange={handleChange}
              required
              data-testid="email"
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="phone">Dealer Phone Number</label>
            <input
              name="phone"
              id="phone"
              type="tel"
              placeholder="(123)-456-7890"
              onChange={handleChange}
              required
              data-testid="phone"
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="affiliation_id">Partner Affiliation</label>
            <select
              name="affiliation_id"
              id="affiliation_id"
              onChange={handleChange}
              value={values.affiliation_id}
            >
              <option>Select a partner affiliation</option>
              {accounts.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="website_url">Dealer Website Link</label>
            <input
              name="website_url"
              id="website_url"
              type="url"
              placeholder="www.website.com"
              onChange={handleChange}
              required
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="address">Dealer Address</label>
            <input
              name="address"
              id="address"
              type="text"
              placeholder="1138 Main St, Suite C"
              onChange={handleChange}
              required
              data-testid="address"
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="city">City</label>
            <input
              name="city"
              id="city"
              type="text"
              placeholder="Boulder"
              onChange={handleChange}
              required
              data-testid="city"
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="state">State</label>
            <select name="state" id="state" onChange={handleChange} value={values.state}>
              <option>Select a state</option>
              {options}
            </select>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="zipCode">zipCode</label>
            <input
              name="zipCode"
              id="zipCode"
              type="text"
              placeholder="80218"
              onChange={handleChange}
              required
              data-testid="zipCode"
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="password">Password</label>
            <input
              name="password"
              id="password"
              type="password"
              placeholder=""
              onChange={handleChange}
              required
              data-testid="password"
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              placeholder=""
              onChange={handleChange}
              required
              data-testid="confirmPassword"
            />
          </InputWrapper>
          <p style={{ marginTop: '0', color: '#777' }} className="italic-small">
            Password must be at least
            <span style={validLength ? validStyle : invalidStyle}> 8 characters</span>,
            <span style={hasNumber ? validStyle : invalidStyle}> 1 number</span>,
            <span style={specialChar ? validStyle : invalidStyle}> 1 special character </span>
            and
            <span style={match ? validStyle : invalidStyle}> match</span>.
          </p>
        </FormSection>
        <Modal>
          <ModalOpenButton>
            <Button type="submit" disabled={loading}>
              Sign Up
            </Button>
          </ModalOpenButton>
          {success ? (
            <ModalContents
              label="Sign up confirmation Modal"
              disableEscapeKeyDown
              disableBackdropClick
            >
              <ModalHeader>
                <ModalDismissButton>
                  <CloseModalButton onClick={loginRedirect}>
                    <span aria-hidden>X</span>
                  </CloseModalButton>
                </ModalDismissButton>
              </ModalHeader>
              <ModalTitle>Thank you for registering as a new TradeUP Dealer!</ModalTitle>
              <div>
                <p>
                  You will receive a confirmation email shortly with an agreement to sign. Upon
                  signing the agreement, your dealer profile will be activated. Please reach out to{' '}
                  <a href={`mailto:${contactEmail}`}>{contactEmail}</a> with any questions.
                </p>
              </div>
              <ModalButton onClick={loginRedirect}>Log In</ModalButton>
            </ModalContents>
          ) : null}
        </Modal>
        <LoaderWrapper>
          {loading && <Loader />}
          {error && <ErrorMsg>{error}</ErrorMsg>}
          {success && <SuccessMsg>{success}</SuccessMsg>}
        </LoaderWrapper>
      </ViewForm>
    </SignUpFormWrapper>
  );
};

export default withTheme(SignUpForm);
