import styled from "styled-components";
import { CircularProgress } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: inherit;
  justify-content: center;
  align-items: center;
`;

const Loader = (): React.ReactElement => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
}

export default Loader;