import CookiesUtil from 'universal-cookie';

class Cookies {
  cookies = new CookiesUtil();

  set = this.cookies.set.bind(this.cookies);
  get = this.cookies.get.bind(this.cookies);

  clear() {
    const allCookies = this.cookies.getAll();
    for (const c in allCookies) {
      this.cookies.remove(c);
    }
  }

  setAll(values: { [key: string]: string }) {
    for (const v in values) {
      this.cookies.set(v, values[v]);
    }
  }
}

const cookies = new Cookies();

export default cookies;
