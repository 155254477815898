import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { Button } from 'components/_styled';
import pick from 'lodash.pick';
import { useEffect, useState } from 'react';
import Loader from 'shared/components/Loader';
import { trackPhotoUpload, trackSubmitSuccess } from 'shared/utils/analytics';
import api from 'shared/utils/api';
import stateOptions from 'utils/stateOptions';
import PhotoUpload from '../PhotoUpload';
import { Form, useForm } from './components/useForm';
import { DealerInput, DealerSelect } from './controls';
import {
  CenteredColumn,
  CharacterCount,
  ContentWrapper,
  ErrorMsg,
  Explanation,
  FormSection,
  HeaderUnderline,
  InlineRow,
  InputWrapper,
  LabelWrapper,
  LoaderWrapper,
  Option,
  SectionHeaders,
  Select,
  StateSelectWrapper,
} from './_styled';

const customerServiceEmail = 'customerservice@theproscloset.com';
const NOTES_MAX_CHARACTERS = 250;
const ADDITIONAL_PHOTOS_ROW = 3;
const ADDITIONAL_PHOTOS_LIST = [0, 1, 2];

const initialValues = {
  dealers: [],
  dealerId: '',
  brand: '',
  model: '',
  year: '',
  serial_number: '',
  size: '',
  disciplines: [],
  disciplineId: '',
  description: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  preferredShopContact: '',
  shopEmail: '',
};

export interface SubmitResponse {
  submissionId: number;
  customerId: string;
}

interface Discipline {
  discipline: string;
  id: number;
}

interface Dealer {
  name: string;
  id: number;
}

// needed to remove the required asterisks on all input fields
const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        display: 'none',
      },
    },
  },
});

export default function DealerForm(): React.ReactElement {
  const { values, setValues, handleInputChange, handleReset } = useForm(initialValues);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [driveSidePhoto, setDriveSidePhoto] = useState('');
  const [nonDriveSidePhoto, setNonDriveSidePhoto] = useState('');
  const [additionalPhotos, setAdditionalPhotos] = useState(['', '', '']); // empty strings are stripped before POST

  const handleSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLoading(true);
    setSubmitSuccess(false);
    setSubmitError(false);

    try {
      const url = '/partners/dealer/submission';
      const data = {
        item: {
          ...pick(values, [
            'brand',
            'model',
            'description',
            'year',
            'serial_number',
            'size',
            'discipline_id',
          ]),
          driveSidePhoto,
          nonDriveSidePhoto,
          additionalPhotos: additionalPhotos.filter((s) => Boolean(s)),
        },
        submission: pick(values, [
          'dealerId',
          'firstName',
          'lastName',
          'email',
          'phone',
          'address',
          'city',
          'state',
          'zip',
          'preferredShopContact',
          'shopEmail',
        ]),
      };
      const result = await api.post<SubmitResponse>(url, data);
      setSubmitSuccess(true);
      setLoading(false);
      trackSubmitSuccess({ req: data, res: result });
    } catch (error) {
      console.error(error);
      setSubmitError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    const disciplinesUrl = '/partners/disciplines';
    const dealersUrl = '/partners/currentUser/dealersByGroup';
    const fetchData = async () => {
      try {
        const fetchedDisciplines = await api.get<Discipline[]>(disciplinesUrl);
        const fetchedDealers = await api.get<Dealer[]>(dealersUrl);
        const dealerId = fetchedDealers.length === 1 ? fetchedDealers[0].id.toString() : '';
        setValues((prevState) => ({
          ...prevState,
          disciplines: fetchedDisciplines,
          dealers: fetchedDealers,
          dealerId,
        }));
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, [setValues]);

  const trackPhoto = ({ type, url }: { type: string; url: string }): void => {
    const photos = [driveSidePhoto, nonDriveSidePhoto, ...additionalPhotos];
    trackPhotoUpload({ type, url, photos });
  };

  if (submitSuccess) {
    const submitAgain = () => (handleReset(), setSubmitSuccess(false));
    return (
      <ContentWrapper style={{ fontSize: '18px' }}>
        <p>{`Thank you for submitting your ${values.brand} ${values.model}!`}</p>
        <p>We have emailed you a confirmation, and our buyers are reviewing your submission.</p>
        <p>If your bike qualifies, you'll receive an offer in 24 hours.</p>
        <br />
        <Button onClick={submitAgain} style={{ margin: '0 auto' }}>
          submit again
        </Button>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <MuiThemeProvider theme={formLabelsTheme}>
        <Form onSubmit={handleSubmit}>
          <FormSection>
            <SectionHeaders>Bike Information</SectionHeaders>
            <HeaderUnderline />
            <DealerSelect
              name="dealerId"
              label="Dealer"
              value={values.dealerId}
              onChange={handleInputChange}
              options={values.dealers}
              required
            />
            <DealerInput
              name="brand"
              label="Brand"
              value={values.brand}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              name="model"
              label="Model"
              value={values.model}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              name="year"
              label="Year"
              value={values.year}
              onChange={handleInputChange}
              required
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
            <DealerInput
              name="serial_number"
              label="Serial Number"
              value={values.serial_number}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              name="size"
              label="Size"
              value={values.size}
              onChange={handleInputChange}
              required
            />
            <DealerSelect
              name="disciplineId"
              label="Disciplines"
              value={values.disciplineId}
              onChange={handleInputChange}
              required
              options={values.disciplines}
            />
            <InlineRow>
              <InputWrapper>
                <LabelWrapper>
                  <label htmlFor="driveSideUpload">Drive side</label>
                </LabelWrapper>
                <PhotoUpload
                  alt="drive side photo"
                  type="driveSide"
                  dealer
                  id="driveSideUpload"
                  photoUrl={driveSidePhoto}
                  clearImage={() => setDriveSidePhoto('')}
                  onUploadSuccess={(url: string) => {
                    setDriveSidePhoto(url);
                    trackPhoto({ type: 'front', url });
                  }}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <label htmlFor="nonDriveSideUpload">Non-drive side</label>
                </LabelWrapper>
                <PhotoUpload
                  alt="non-drive side photo"
                  type="nonDriveSide"
                  dealer
                  id="nonDriveSideUpload"
                  photoUrl={nonDriveSidePhoto}
                  clearImage={() => setNonDriveSidePhoto('')}
                  onUploadSuccess={(url: string) => {
                    setNonDriveSidePhoto(url);
                    trackPhoto({ type: 'back', url });
                  }}
                  required
                />
              </InputWrapper>
            </InlineRow>
            <InputWrapper>
              <div>
                <label>Additional photos (optional)</label>
              </div>
              <InlineRow rowLength={ADDITIONAL_PHOTOS_ROW}>
                {ADDITIONAL_PHOTOS_LIST.map((idx) => (
                  <PhotoUpload
                    key={idx}
                    alt="additional photo"
                    type="additional"
                    dealer
                    id={`additionalPhoto${idx}`}
                    photoUrl={additionalPhotos[idx]}
                    clearImage={() => setAdditionalPhotos(['', '', ''])}
                    onUploadSuccess={(url: string) => {
                      setAdditionalPhotos((prevState) => {
                        const newState = [...prevState];
                        newState[idx] = url;
                        return newState;
                      });
                      trackPhoto({ type: 'other', url });
                    }}
                  />
                ))}
              </InlineRow>
            </InputWrapper>
            <InputWrapper>
              <DealerInput
                name="description"
                label="Notes"
                value={values.description}
                onChange={handleInputChange}
                required
                multiline
                rows={6}
                inputProps={{ maxLength: NOTES_MAX_CHARACTERS }}
              />
              <CharacterCount>
                {NOTES_MAX_CHARACTERS - values.description.length} characters left
              </CharacterCount>
            </InputWrapper>
          </FormSection>

          <FormSection>
            <SectionHeaders>Customer Contact Information (bike owner)</SectionHeaders>
            <HeaderUnderline />
            <Explanation>
              Please make sure to input the customer's information here. Your customer will receive
              the offer email and will need to verify important contact information regarding their
              bike submission.
            </Explanation>
            <DealerInput
              name="firstName"
              label="Customer First Name"
              value={values.firstName}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              name="lastName"
              label="Customer Last Name"
              value={values.lastName}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              type="email"
              name="email"
              label="Customer Email"
              value={values.email}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              name="phone"
              label="Customer Phone Number"
              value={values.phone}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              name="address"
              label="Customer Address"
              value={values.address}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              name="city"
              label="City"
              value={values.city}
              onChange={handleInputChange}
              required
            />
            <StateSelectWrapper data-testid="state">
              <Select
                name="state"
                id="state"
                value={values.state}
                onChange={handleInputChange}
                required
                className="dealerFormStyle"
              >
                <Option value="">State</Option>
                {stateOptions}
              </Select>
            </StateSelectWrapper>
            <DealerInput
              name="zip"
              label="Zip Code"
              value={values.zip}
              onChange={handleInputChange}
              inputProps={{ maxLength: 5, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </FormSection>

          <FormSection>
            <SectionHeaders>
              Shop Contact
              <br /> (affiliated bike shop)
            </SectionHeaders>
            <HeaderUnderline />
            <DealerInput
              name="preferredShopContact"
              label="Preferred Shop Contact"
              value={values.preferredShopContact}
              onChange={handleInputChange}
              required
            />
            <DealerInput
              type="email"
              name="shopEmail"
              label="Email"
              value={values.shopEmail}
              onChange={handleInputChange}
              required
            />
          </FormSection>
          <CenteredColumn>
            <Button disabled={loading}>submit</Button>
            <LoaderWrapper>
              {loading && <Loader />}
              {submitError && (
                <ErrorMsg>
                  There was an error submitting your bike. Please verify that all your information
                  is correct and try again or contact{' '}
                  <a href={`mailto:${customerServiceEmail}`}>{customerServiceEmail}</a>
                </ErrorMsg>
              )}
            </LoaderWrapper>
          </CenteredColumn>
        </Form>
      </MuiThemeProvider>
    </ContentWrapper>
  );
}
