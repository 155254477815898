import styled from 'styled-components';
import DealerForm from 'shared/components/submission/DealerForm';
import caret from 'images/caret.svg';
import { useContext, useState } from 'react';
import DealerProgramPausedView from './DealerProgramPausedView';
import userContext from 'shared/userContext';

const ViewWrapper = styled.div`
  min-height: calc(100vh - 5rem);
  padding-bottom: 3rem;
  background-color: white;
`;

const DrawerWrapper = styled.div`
  max-width: 100vw;
  min-width: 23rem;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    width: 100%;
  }
`;

const InstructionsWrapper = styled.div<{ isDrawerOpen: boolean }>`
  margin: 1rem auto 0;
  display: ${({ isDrawerOpen }) => (isDrawerOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;

  > span {
    text-align: left;

    > h4 {
      margin-top: 1rem;
      margin-bottom: 0;
    }
    > p {
      margin-top: 0.5rem;
      color: #626366;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    flex-direction: row;
    display: flex;

    > span {
      max-width: 13rem;
      padding: 0 1rem 1rem;
      text-align: center;
    }
  }
  border-bottom: solid #929497;
`;

const TradeupLogoImg = styled.img`
  max-width: 20rem;
  min-width: 10rem;
  margin: 2rem auto 0;
`;

const SubHeadingWrapper = styled.div`
  margin: 1rem auto 0;

  > h3 {
    margin: 1rem 0 0;
    text-align: center;
  }

  > p {
    text-transform: uppercase;
    font-size: 14px;

    > span {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

const CaretImg = styled.img`
  max-height: 3rem;
  width: 100%;
  cursor: pointer;
  padding: 1rem 0;
  box-sizing: content-box;

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    display: none;
  }
`;

interface Props {
  tradeupLogo: string;
}

const SubmissionFormView = ({ tradeupLogo }: Props): React.ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isProgramPaused } = useContext(userContext);

  return (
    <ViewWrapper>
      {isProgramPaused ? (
        <DealerProgramPausedView tradeupLogo={tradeupLogo} />
      ) : (
        <>
          <DrawerWrapper>
            <TradeupLogoImg src={tradeupLogo} alt="TradeUP with The Pro's Closet" />
            <SubHeadingWrapper>
              <p style={{ textTransform: 'uppercase', fontSize: '14px' }}>
                Fill out <span>all</span> information to submit customer's bike for review
              </p>
            </SubHeadingWrapper>
            <InstructionsWrapper isDrawerOpen={isDrawerOpen}>
              <>
                <span>
                  <h4>
                    Step 1:
                    <br /> Submit Bike
                  </h4>
                  <p>Fill in the information below to get your customer their offer</p>
                </span>
                <span>
                  <h4>
                    Step 2:
                    <br /> Get Offer
                  </h4>
                  <p>A TradeUP offer will be emailed to your customer in 24 hours</p>
                </span>
                <span>
                  <h4>
                    Step 3:
                    <br /> Complete Details
                  </h4>
                  <p>Your customer will accept their offer and complete required information</p>
                </span>
                <span>
                  <h4>
                    Step 4:
                    <br /> New Bike Day!
                  </h4>
                  <p>
                    Your customer will bring in their bike and voucher and get rolling on their new
                    bike
                  </p>
                </span>
              </>
            </InstructionsWrapper>
            <CaretImg
              src={caret}
              alt="Expand/Collapse Description"
              style={{ transform: isDrawerOpen ? 'rotate(180deg)' : '' }}
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            />
          </DrawerWrapper>
          <DealerForm />
        </>
      )}
    </ViewWrapper>
  );
};

export default SubmissionFormView;
