import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';

interface StatusTooltipProps {
  title: string;
  description: string;
}

const useStyles = makeStyles((theme) => ({
  cardTooltip: {
    padding: theme.spacing(1),
    maxWidth: theme.spacing(48),
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[4],
    borderRadius: theme.shape.borderRadius,
  },
}));

export const StatusTooltip: React.FC<StatusTooltipProps> = ({
  title,
  description,
}): React.ReactElement | null => {
  const classes = useStyles();

  if (!(title || description)) {
    return null;
  }

  return (
    <Tooltip
      placement="bottom"
      classes={{
        tooltip: classes.cardTooltip,
      }}
      arrow
      enterDelay={150}
      disableFocusListener
      title={
        <React.Fragment>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemText primary={title} secondary={description} />
            </ListItem>
          </List>
        </React.Fragment>
      }
    >
      <IconButton disableRipple tabIndex={-1}>
        <svg
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
        </svg>
      </IconButton>
    </Tooltip>
  );
};
