import { withTheme } from 'styled-components';
import { Theme } from 'shared/_types';
import { BaseThemedComponent, BaseSvg as Svg } from 'shared/_base';

interface NonDriveSideProps {
  theme: Theme;
  style: React.CSSProperties;
}

class NonDriveSide extends BaseThemedComponent<NonDriveSideProps> {
  render() {
    const { style, theme } = this.props;
    return (
      // originally "0 0 127.49 127.49", updated to intentionally clip bottom + right of white circle for visual balance
      <Svg viewBox="0 0 115 115" style={style}>
        <path d="M67.56,70.85a20.88,20.88,0,0,1,.81-2.65A12.17,12.17,0,0,1,65,61h7.9a21.67,21.67,0,0,1,3.94-3.27l-4.66-9.11A12.15,12.15,0,0,1,88,54.28l.57,0c.74,0,1.48,0,2.2.11a14.63,14.63,0,0,0-19.72-8L66.78,38l3-6.79h4a1.25,1.25,0,0,0,0-2.5H63a1.25,1.25,0,0,0,0,2.5h4l-2.5,5.58H44.93l1.77-3.71a1.25,1.25,0,0,0-.08-1.2,1.22,1.22,0,0,0-1-.59H36.3a5.17,5.17,0,0,0,0,10.33h.44a1.25,1.25,0,0,0,0-2.5H36.3a2.67,2.67,0,0,1,0-5.33h7.29L41.84,37.5l-4.63,9.09a14.69,14.69,0,1,0,2.15,1.3l.88-1.73L56.71,60.68h0a1.36,1.36,0,0,0,.35.21l.09,0a1.22,1.22,0,0,0,.38.07h5A14.68,14.68,0,0,0,67.56,70.85ZM42.9,59.74A12.18,12.18,0,1,1,30.72,47.56a12,12,0,0,1,5.36,1.26L30.81,59.17a1.25,1.25,0,0,0,.55,1.68,1.17,1.17,0,0,0,.57.14A1.25,1.25,0,0,0,33,60.31l5.17-10.16A12.16,12.16,0,0,1,42.9,59.74Zm31.54-1.25H65a12.18,12.18,0,0,1,5-8.63ZM57,57.59,41.52,44l2.22-4.63H63.61Zm2.34.9L65.59,41.2l3.28,6.41A14.7,14.7,0,0,0,62.5,58.49Z" />
        <path
          d="M83.92,68l3.4-3.4V77.5a1.25,1.25,0,0,0,2.5,0V64.62l3.4,3.4a1.25,1.25,0,0,0,.89.37A1.21,1.21,0,0,0,95,68a1.24,1.24,0,0,0,0-1.76l-5.53-5.54a1.62,1.62,0,0,0-.41-.27,1.33,1.33,0,0,0-1,0,1.41,1.41,0,0,0-.4.27l-5.54,5.54A1.25,1.25,0,1,0,83.92,68Z"
          fill={theme.primary}
        />
        <path
          d="M99,75.13a1.25,1.25,0,0,0-1.25,1.25v8.18H79.38V76.38a1.25,1.25,0,0,0-2.5,0v9.43a1.25,1.25,0,0,0,1.25,1.25H99a1.25,1.25,0,0,0,1.25-1.25V76.38A1.25,1.25,0,0,0,99,75.13Z"
          fill={theme.primary}
        />
      </Svg>
    );
  }
}

export default withTheme(NonDriveSide);
