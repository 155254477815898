import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DetailRow } from 'components/submission/detail/_styled';

const buttonStyles = `
  background-color: black;
  display: inline-block;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  padding: .75rem 2.5rem;
  border: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 5px rgba(0,0,0,.3);
  }
  &:disabled {
    background-color: #ccc;
  }
`;

export const Button = styled.button`
  ${buttonStyles}
`;
export const LinkButton = styled.a`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.tpcGreen};
  text-decoration: none;
  font-family: ${({ theme }) => theme.secondaryFont};
  text-align: center;
`;
export const RouterLinkButton = styled(Link)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.primary};
  text-decoration: none;
  font-family: ${({ theme }) => theme.secondaryFont};
  text-align: center;
`;

export const ContentWrapper = styled.div`
  max-width: 40rem;
  min-width: 20rem;
  margin: 3rem auto 0;
  width: 100%;
  background-color: white;
  padding: 0.5rem 1rem 3rem;

  @media (min-width: ${({ theme }) => theme.breakpointSmall}) {
    padding: 1rem 2rem 3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    padding: 2rem 4rem 3rem;
  }
`;

export const DataForm = styled(ContentWrapper).attrs({ as: 'form' })``;
export const ViewForm = styled(DataForm)`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;

  > input {
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    height: 3rem;
  }

  > ${Button} {
    min-width: 10rem;
  }
`;

export const ResponsiveRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  > ${DetailRow} {
    border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
`;

export const ErrorMsg = styled.p`
  margin-bottom: 0;
  color: red;
`;

export const SuccessMsg = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.tpcGreen}
`;
