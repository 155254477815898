import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import userContext, {
  UserContext,
  PartnerSiteUser,
  handleLoginCookies,
  handleLogoutCookies,
  getUserDataFromCookies,
  hasDealerFunctionality,
  signUpNewDealers,
  isProgramPaused,
  hasIframeFunctionality,
  showSupportPhone,
} from 'shared/userContext';
import Header, { HeaderLink, headerHeight } from 'components/Header';
import { LinkButton, RouterLinkButton } from './_styled';
import { trackPageView } from 'shared/utils/analytics';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  min-height: calc(100vh - ${headerHeight});

  // theme-dependent global styles should go here (the highest level where theme is accessible)
  input,
  textarea {
    &::placeholder {
      color: ${({ theme }) => theme.mediumGray};
      opacity: 1; /* Firefox */
    }
  }

  a {
    &:not(${LinkButton}):not(${RouterLinkButton}) {
      &:link,
      &:visited,
      &:focus {
        color: ${({ theme }) => theme.primary};
      }
    }
  }
`;

interface Props {
  headerLinks?: HeaderLink[];
}

const AppWrapper: FunctionComponent<Props> = ({ children, headerLinks }): React.ReactElement => {
  const [user, setUser] = useState<PartnerSiteUser | null>(getUserDataFromCookies());
  const location = useLocation();

  useEffect(trackPageView, [location.pathname]);

  const ctxValue: UserContext = {
    user,
    login: (user: PartnerSiteUser) => {
      handleLoginCookies(user || null);
      setUser(user);
    },
    logout: () => {
      handleLogoutCookies();
      setUser(null);
    },
    hasDealerFunctionality,
    signUpNewDealers,
    isProgramPaused,
    hasIframeFunctionality,
    showSupportPhone,
  };
  const regex = new RegExp('^/customer/form/[a-zA-Z0-9-]{8,}');
  const isDealerForm = regex.test(location.pathname);
  return (
    <>
      <userContext.Provider value={ctxValue}>
        {!isDealerForm && headerLinks && <Header links={headerLinks} />}
        <Wrapper>{children}</Wrapper>
      </userContext.Provider>
    </>
  );
};

export default AppWrapper;
