import { Snackbar as MuiSnackbar, SnackbarProps } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import styled from 'styled-components';

const Message = styled.div`
  max-width: 400px;
`;

const SnackBar: React.FC<SnackbarProps> = ({ message, ...rest }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiSnackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} {...rest}>
      <Alert onClose={handleClose} variant="filled" severity="info">
        <Message>{message}</Message>
      </Alert>
    </MuiSnackbar>
  );
};

export default SnackBar;
