import styled from 'styled-components';
import CustomerForm from 'components/submission/CustomerForm';
import caret from 'images/caret.svg';
import { useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import userContext from 'shared/userContext';
import NoIFrameMessageView from './NoIFrameMessageView';

const ViewWrapper = styled.div`
  min-height: calc(100vh - 5rem);
  padding-bottom: 3rem;
  ${({ theme }) => {
    if (!theme.images.submissionBackground) {
      return '';
    } else {
      return `
        @media(min-width: ${theme.breakpointMedium}) {
          background: url(${theme.images.submissionBackground}) no-repeat fixed center;
          background-size: cover;
        }
      `;
    }
  }}
`;

const DrawerWrapper = styled.div`
  max-width: 100vw;
  min-width: 23rem;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    width: 100%;
  }
`;

const InstructionsWrapper = styled.div<{ isDrawerOpen: boolean }>`
  margin: 1rem auto 0;
  display: ${({ isDrawerOpen }) => (isDrawerOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;

  > span {
    text-align: left;

    > h4 {
      margin-top: 1rem;
      margin-bottom: 0;
    }
    > p {
      margin-top: 0.5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    flex-direction: row;
    display: flex;

    > span {
      max-width: 13rem;
      padding: 1rem;
      text-align: center;
    }
  }
`;

const TradeupLogoImg = styled.img`
  max-width: 20rem;
  min-width: 10rem;
  margin: 2rem auto 0;
`;

const CaretImg = styled.img`
  max-height: 3rem;
  width: 100%;
  cursor: pointer;
  padding: 1rem 0;
  box-sizing: content-box;

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    display: none;
  }
`;

interface Props {
  tradeupLogo: string;
}

const SubmissionFormView = ({ tradeupLogo }: Props): React.ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { dealerToken } = useParams<{ dealerToken: string }>();
  const { hasIframeFunctionality } = useContext(userContext);

  return !hasIframeFunctionality ? (
    <NoIFrameMessageView tradeupLogo={tradeupLogo} />
  ) : (
    <ViewWrapper>
      <DrawerWrapper>
        <TradeupLogoImg src={tradeupLogo} alt="TradeUP with The Pro's Closet" />
        <InstructionsWrapper isDrawerOpen={isDrawerOpen}>
          <span>
            <h4>Step 1</h4>
            <p>Submit your bike details in the form below</p>
          </span>
          <span>
            <h4>Step 2</h4>
            <p>Receive & accept your offer</p>
          </span>
          <span>
            <h4>Step 3</h4>
            <p>Receive your voucher</p>
          </span>
          <span>
            <h4>Step 4</h4>
            <p>Bring your bike and voucher to the selected dealer and pick out a new bike</p>
          </span>
        </InstructionsWrapper>
        <CaretImg
          src={caret}
          alt="Expand/Collapse Description"
          style={{ transform: isDrawerOpen ? 'rotate(180deg)' : '' }}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      </DrawerWrapper>
      <CustomerForm dealerToken={dealerToken} />
    </ViewWrapper>
  );
};

export default SubmissionFormView;
