import { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import tpcLogo from 'images/tpc-logo.svg';
import userContext from 'shared/userContext';
import {
  Modal,
  ModalContents,
  ModalOpenButton,
  ModalCodeSection,
  ModalButton,
  CloseModalButton,
  ModalDismissButton,
  ModalHeader,
  ModalTitle,
} from './modal/Modal';
import { Alert, AlertTitle } from '@material-ui/lab';
import api from 'shared/utils/api';
import CopyToClipboard from 'shared/utils/CopyToClipboard';
import Loader from 'components/Loader';
import { contactEmail } from './submission/SignUpForm/SignUpForm';

const APP_URL = process.env.REACT_APP_HOST;

export const headerHeight = '80px';

const HeaderBar = styled.div`
  height: ${headerHeight};
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 15rem;
  height: 100%;
  margin-left: 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    width: 20rem;
  }
`;

const linkStyles = `
  color: black;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledRouterLink = styled(Link)`
  ${linkStyles}
`;
const StyledNativeLink = styled.a`
  ${linkStyles}
`;
const StyledOnClick = styled.span`
  ${linkStyles}
  cursor: pointer;
`;

const LinkContainer = styled.div`
  > ${StyledRouterLink}, ${StyledOnClick}, ${StyledNativeLink} {
    padding-right: 1.5rem;
  }
`;

const ErrorWrapper = styled.div`
  padding: 1.5rem 0;
`;

export const LoaderWrapper = styled.div`
  margin: 0px -2rem;
  padding: 0.5rem 0;
  height: 4.5rem;
`;

export type HeaderLink =
  | {
      text: string;
      to: string;
    }
  | {
      text: string;
      onClick: () => any;
    }
  | {
      text: string;
      href: string;
    };

interface Props {
  links?: HeaderLink[];
}

interface CurrentDealerToken {
  name: string;
  token: string;
}

const Header = ({ links = [] }: Props): React.ReactElement => {
  const { user, logout, hasDealerFunctionality, hasIframeFunctionality } = useContext(userContext);
  const [currentDealer, setCurrentDealer] = useState<CurrentDealerToken>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const iframeCode = `<div class="iframe-container"><iframe title="tpc trade up" src=${APP_URL}/customer/form/${currentDealer?.token} width="100%" height="600" allowfullscreen></iframe></div>`;
  const errorAlert = error && (
    <Alert severity="error">
      <AlertTitle>
        <b>Error</b>
      </AlertTitle>
      {error} <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
    </Alert>
  );

  const fetchCurrentDealer = () => {
    if (user && hasDealerFunctionality) {
      setLoading(true);
      const fetchDealer = async () => {
        const url = '/partners/currentUser/dealerToken';
        try {
          const currentDealer = await api.get<CurrentDealerToken>(url);
          setCurrentDealer(currentDealer);
          setLoading(false);
          setError(null);
        } catch (error) {
          console.error(error);
          setLoading(false);
          setError(
            `Cannot get your iframe code at the moment, please check back shortly or contact`,
          );
        }
      };
      fetchDealer();
    }
  };

  const loginLogoutLink = user
    ? { text: 'Logout', onClick: logout }
    : { text: 'Login', to: '/login' };

  const headerLinks = [...links, loginLogoutLink];

  return (
    <HeaderBar>
      <Link to={user && hasDealerFunctionality ? '/submissions' : '/'} style={{ height: '100%' }}>
        <Logo src={tpcLogo} alt="The Pro's Closet" />
      </Link>
      <LinkContainer>
        {user && hasIframeFunctionality ? (
          <Modal>
            <ModalOpenButton>
              <StyledOnClick onClick={fetchCurrentDealer}>Get iframe</StyledOnClick>
            </ModalOpenButton>
            {loading && (
              <ModalContents label="Loading iframe Modal">
                <ModalHeader />
                <ModalTitle>GETTING IFRAME...</ModalTitle>
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              </ModalContents>
            )}
            {error && (
              <ModalContents label="iframe error">
                <ModalHeader>
                  <ModalDismissButton>
                    <CloseModalButton>
                      <span aria-hidden>X</span>
                    </CloseModalButton>
                  </ModalDismissButton>
                </ModalHeader>
                <ModalTitle>Get iframe failed</ModalTitle>
                <ErrorWrapper>
                  <span>{errorAlert}</span>
                </ErrorWrapper>
              </ModalContents>
            )}
            {!error && !loading && (
              <ModalContents label="iframe Modal">
                <ModalHeader>
                  <ModalDismissButton>
                    <CloseModalButton>
                      <span aria-hidden>X</span>
                    </CloseModalButton>
                  </ModalDismissButton>
                </ModalHeader>
                <ModalTitle>Get iframe</ModalTitle>
                <div>
                  <p>
                    This code can be placed on the dealers site and is unique to{' '}
                    {currentDealer?.name}. The token for this dealer is:{' '}
                    <span>
                      <b>
                        {currentDealer?.token} <br />
                        NOTE:
                      </b>
                    </span>{' '}
                    This is NOT a private token and is used for dealer identification only.
                  </p>
                </div>
                <ModalCodeSection className="shadow">
                  <code>{iframeCode}</code>
                </ModalCodeSection>
                <CopyToClipboard>
                  {({ copy }) => (
                    <ModalButton onClick={() => copy(iframeCode)}>Copy iframe</ModalButton>
                  )}
                </CopyToClipboard>
              </ModalContents>
            )}
          </Modal>
        ) : null}
        {headerLinks.map((l) => {
          if ('onClick' in l) {
            return (
              <StyledOnClick key={l.text} onClick={l.onClick}>
                {l.text}
              </StyledOnClick>
            );
          } else if ('href' in l) {
            return (
              <StyledNativeLink key={l.text} href={l.href} target="_blank">
                {l.text}
              </StyledNativeLink>
            );
          } else if ('to' in l) {
            return (
              <StyledRouterLink key={l.text} to={l.to}>
                {l.text}
              </StyledRouterLink>
            );
          }
          return null;
        })}
      </LinkContainer>
    </HeaderBar>
  );
};

export default Header;
