import styled from 'styled-components';

export const FormSection = styled.div`
  margin-bottom: 2.5rem;
`;

export const InlineRow = styled.div<{ rowLength?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ rowLength = 2 }) => rowLength}, 1fr);
  grid-gap: 1rem;
  align-items: center;
`;

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
  position: relative;
`;

export const Explanation = styled.p`
  margin-top: 0;
  color: ${({ theme }) => theme.mediumGray};
`;

export const CharacterCount = styled.span`
  --spacing: .4rem; // stay clear of text entered in the row above
  position: absolute;
  bottom: var(--spacing);
  right: var(--spacing);
  color: ${({ theme }) => theme.mediumGray};
`;

export const ErrorMsg = styled.p`
  color: red;
`;

export const SuccessMsg = styled.p`
  color: ${({ theme }) => theme.primary};
`;

export const CenteredColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;