import { Component } from 'react';
import styled from 'styled-components';
import { Theme } from './_types';

const lightGray = '#eeeeee';
// https://stackoverflow.com/questions/23390831/why-css-dark-grey-is-lighter-then-the-original-grey
const baseTheme: Theme = {
  breakpointSmall: '480px',
  breakpointMedium: '768px',
  background: lightGray,
  lightGray,
  mediumGray: 'darkgray',
  darkGray: 'gray',
  primary: '#00c160',
  tpcGreen: '#00c160',
  tpcRed: '#ed1c24',
  primaryFont: 'Arial, Helvetica, sans-serif',
  secondaryFont: "'Arial Black', 'Arial Bold', Gadget, sans-serif",
};

class BaseThemedComponent<PROPS> extends Component<PROPS> {
  static defaultProps = { theme: baseTheme };
}

const BaseSvg = styled.svg.attrs({ 
  version: '1.1', 
  xmlns: 'http://www.w3.org/2000/svg', 
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
  width: 100%;
  height: 100%;
`;

export {
  baseTheme,
  BaseThemedComponent,
  BaseSvg,
};