import { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import userContext, { PartnerSiteRole } from 'shared/userContext';

interface Props extends RouteProps {
  role?: PartnerSiteRole;
  tradeupLogo?: string;
}

const PrivateRoute = (props: Props): React.ReactElement => {
  const { component: RouteComponent, role, ...rest } = props;
  const { user } = useContext(userContext);

  if (!RouteComponent) {
    throw new Error('PrivateRoute must be passed a `component` prop containing a React component');
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const userHasRoleIfRequired = role ? user?.role === role : true;
        return user && userHasRoleIfRequired ? (
          <RouteComponent {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
