import { DetailWrapper, DetailRow, TopAlignedDetailRow, DataLabel } from './_styled';
import { ResponsiveRow } from 'components/_styled';

export interface SubmissionProp {
  serial_number: string;
  Items: {
    year: number;
    brand: string;
    model: string;
    confirmed_brand?: string;
    confirmed_model?: string;
    description?: string;
  }[];
}
interface Props {
  submission: SubmissionProp;
}

const BikeInfo = (props: Props): React.ReactElement => {
  const {
    submission,
    submission: { Items },
  } = props;

  // todo: handle multiple items?
  const item = Items[0];

  return (
    <>
      <h2>Bike Info</h2>
      <DetailWrapper>
        <ResponsiveRow>
          <DetailRow>
            <DataLabel>Brand:</DataLabel>
            <span>{item.confirmed_brand || item.brand}</span>
          </DetailRow>
          <DetailRow>
            <DataLabel>Model:</DataLabel>
            <span>{item.confirmed_model || item.model}</span>
          </DetailRow>
        </ResponsiveRow>
        <DetailRow>
          <DataLabel>Year:</DataLabel>
          <span>{item.year || '--'}</span>
        </DetailRow>
        <DetailRow>
          <DataLabel>Serial #:</DataLabel>
          <span>{submission.serial_number || '--'}</span>
        </DetailRow>
        <TopAlignedDetailRow>
          <DataLabel>Description:</DataLabel>
          <span>{item.description || '--'}</span>
        </TopAlignedDetailRow>
      </DetailWrapper>
    </>
  );
};

export default BikeInfo;
