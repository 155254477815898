import Cookies from 'universal-cookie';
const API_URL = process.env.REACT_APP_API_HOST;

class APIError extends Error {
  name = 'APIError';
  message = '';
  statusCode: number | null = null;

  constructor(init: { message?: string; statusCode?: number }) {
    super(init.message);
    if (init.message) this.message = init.message;
    if (init.statusCode) this.statusCode = init.statusCode;
  }
}

class API {
  private cookies: Cookies = new Cookies();
  private token = '';
  private defaultOptions: RequestInit = {};

  init = () => {
    const token = this.cookies.get('token');

    if (!this.defaultOptions.headers || this.token !== token) {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-partner-site', process.env.REACT_APP_PARTNER_SITE_ID || '');

      if (token) {
        this.token = token;
        headers.append('Authorization', token ? `Bearer ${token}` : '');
      }

      this.defaultOptions = { headers };
    }
  };

  private buildUrl(urlFragment: string) {
    return `${API_URL}${urlFragment}`;
  }

  private async handleResponse<DataType>(promise: Promise<Response>): Promise<DataType> {
    const response = await promise;
    const isJson = response.headers.get('content-type')?.includes('application/json');

    if (!response?.ok) {
      throw new APIError({
        message: isJson ? JSON.stringify(await response.json()) : await response.text(),
        statusCode: response.status,
      });
    }

    return isJson ? response.json() : response.text();
  }

  get = <DataType>(url: string, options = {}) => {
    this.init();
    return this.handleResponse<DataType>(
      fetch(this.buildUrl(url), { ...this.defaultOptions, ...options }),
    );
  };

  post = <DataType>(url: string, data: any, options = {}) => {
    this.init();
    return this.handleResponse<DataType>(
      fetch(this.buildUrl(url), {
        ...this.defaultOptions,
        ...options,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    );
  };

  put = <DataType>(url: string, data: any, options = {}) => {
    this.init();
    return this.handleResponse<DataType>(
      fetch(this.buildUrl(url), {
        ...this.defaultOptions,
        ...options,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
    );
  };

  delete = <DataType>(url: string, options = {}) => {
    this.init();
    return this.handleResponse<DataType>(
      fetch(this.buildUrl(url), {
        ...this.defaultOptions,
        ...options,
        method: 'DELETE',
      }),
    );
  };
}

const instance = new API();

export default instance;
