import styled from 'styled-components';

const MessageWrapper = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-family: Denim;

  > h4 {
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  > p {
    margin-top: 0.5rem;
    > b {
      font-family: 'Denim Semibold';
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    margin: 1rem auto;
    flex-direction: column;
    max-width: 40rem;
    padding: 1rem;
  }
`;

const TradeupLogoImg = styled.img`
  max-width: 20rem;
  min-width: 10rem;
  margin: 2rem auto 0;
`;

interface Props {
  tradeupLogo: string;
}

const DealerProgramPausedView = ({ tradeupLogo }: Props): React.ReactElement => {
  return (
    <>
      <TradeupLogoImg src={tradeupLogo} alt="TradeUP with The Pro's Closet" />
      <MessageWrapper>
        <h4>Program Update</h4>
        <p>
          Thanks for your interest! We're hitting the brakes on accepting more bikes right now
          though.
        </p>
        <p>
          We believe that bikes are meant to be used. Stay tuned, as we'll be reevaluating things
          soon.
        </p>
      </MessageWrapper>
    </>
  );
};

export default DealerProgramPausedView;
