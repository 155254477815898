import { useState, useContext } from 'react';
import styled from 'styled-components';
import api from 'utils/api';
import { ViewForm, Button, RouterLinkButton, ErrorMsg } from 'components/_styled';
import { Description } from './_styled';
import userContext from 'shared/userContext';

const SuccessWrapper = styled(ViewForm).attrs({ as: 'div' })``;

export interface Props {
  token: string;
}

const PasswordResetView = ({ token }: Props): React.ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { logout } = useContext(userContext);

  if (success) {
    return (
      <SuccessWrapper>
        <p>
          Your password has been reset successfully, you may now login with your new credentials.
        </p>
        <RouterLinkButton to="/login">LOGIN</RouterLinkButton>
      </SuccessWrapper>
    );
  }

  return (
    <ViewForm
      onSubmit={async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
          setError('Passwords must match');
          return false;
        }
        if (password.length < 8 || password.length > 32) {
          setError('Password must be between 8 and 32 characters');
          return false;
        }
        if (!/[a-z]/.test(password)) {
          setError('Password must contain a lowercase letter');
          return false;
        }
        if (!/[A-Z]/.test(password)) {
          setError('Password must contain an uppercase letter');
          return false;
        }
        if (!/\d/.test(password)) {
          setError('Password must contain a number');
          return false;
        }
        if (!/[^\w ]|_/g.test(password)) {
          setError('Password must contain a special character');
          return false;
        }
        try {
          await api.post('/partners/password/reset', { password, token });
          setSuccess(true);
          logout();
        } catch (error: any) {
          console.error(error);
          setError(error.message);
        }
      }}
    >
      <Description>Please enter your new password:</Description>
      <input
        autoComplete="new-password"
        type="password"
        placeholder="New Password"
        title="A valid password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
        data-testid="password"
      />
      <input
        autoComplete="new-password"
        type="password"
        placeholder="Confirm New Password"
        title="A valid password"
        value={confirmPassword}
        onChange={({ target }) => setConfirmPassword(target.value)}
        data-testid="confirmPassword"
      />
      <Description>
        Requirements:
        <ul>
          <li>Between 8 and 32 characters in length</li>
          <li>One lowercase character [a-z]</li>
          <li>One uppercase character [A-Z]</li>
          <li>One number [0-9]</li>
          <li>
            One special character, e.g. <br />
            {`*.!@#$%^&(){}[]:;<>,.?/~_+-=|\\`}
          </li>
        </ul>
      </Description>
      <Button type="submit">reset password</Button>
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </ViewForm>
  );
};

export default PasswordResetView;
