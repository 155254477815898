import { withTheme } from 'styled-components';
import { Theme } from 'shared/_types';
import { BaseThemedComponent, BaseSvg as Svg } from 'shared/_base';

interface DriveSideProps {
  theme: Theme;
  style: React.CSSProperties;
}

class DriveSide extends BaseThemedComponent<DriveSideProps> {
  render() {
    const { style, theme } = this.props;
    return (
      // originally "0 0 127.49 127.49", updated to intentionally clip bottom + right of white circle for visual balance
      <Svg viewBox="0 0 115 115" style={style}>
        <path d="M68.37,68.2a12.14,12.14,0,0,1,1.27-18.05l4.78,9.4a20.45,20.45,0,0,1,2-1.57l-4.67-9.16A12.13,12.13,0,0,1,88,54.28l.57,0c.74,0,1.48,0,2.2.11a14.63,14.63,0,0,0-20.14-7.77L66,37.53l-1.76-3.71h7.28a2.67,2.67,0,1,1,0,5.33h-.43a1.25,1.25,0,0,0,0,2.5h.43a5.17,5.17,0,1,0,0-10.33H62.28a1.25,1.25,0,0,0-1.06.59,1.22,1.22,0,0,0-.07,1.2l1.77,3.71H43.26l-2.49-5.58h4a1.25,1.25,0,0,0,0-2.5H34a1.25,1.25,0,0,0,0,2.5h4l3,6.79-4.28,8.36a14.49,14.49,0,0,0-6.07-1.33A14.68,14.68,0,1,0,45.34,61h5a1.28,1.28,0,0,0,.39-.07l.09,0a1.55,1.55,0,0,0,.35-.21h0L67.61,46.16l.88,1.73a14.62,14.62,0,0,0-.93,23A21.39,21.39,0,0,1,68.37,68.2ZM30.72,71.92a12.18,12.18,0,1,1,4.93-23.31l-5.4,10.56A1.25,1.25,0,0,0,31.36,61H42.84A12.2,12.2,0,0,1,30.72,71.92Zm2.69-13.43,4.41-8.63a12.19,12.19,0,0,1,5,8.63Zm11.93,0A14.65,14.65,0,0,0,39,47.61l3.28-6.41,6.27,17.29Zm5.52-.9L44.24,39.32H64.11L66.33,44Z" />
        <path
          d="M83.92,68l3.4-3.4V77.5a1.25,1.25,0,0,0,2.5,0V64.62l3.4,3.4a1.25,1.25,0,0,0,.89.37A1.21,1.21,0,0,0,95,68a1.24,1.24,0,0,0,0-1.76l-5.53-5.54a1.62,1.62,0,0,0-.41-.27,1.33,1.33,0,0,0-1,0,1.41,1.41,0,0,0-.4.27l-5.54,5.54A1.25,1.25,0,1,0,83.92,68Z"
          fill={theme.primary}
        />
        <path
          d="M99,75.13a1.25,1.25,0,0,0-1.25,1.25v8.18H79.38V76.38a1.25,1.25,0,0,0-2.5,0v9.43a1.25,1.25,0,0,0,1.25,1.25H99a1.25,1.25,0,0,0,1.25-1.25V76.38A1.25,1.25,0,0,0,99,75.13Z"
          fill={theme.primary}
        />
      </Svg>
    );
  }
}

export default withTheme(DriveSide);
