import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ContentWrapper, ResponsiveRow } from 'components/_styled';
import { useContext } from 'react';
import userContext from 'shared/userContext';

// todo: move to credstash/appconfig
const contactEmail = 'tradeup@theproscloset.com';
const TPCPhone = '+1 (888) 271-3635';

const Row = styled.div`
  margin-bottom: 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    margin-bottom: 0;
  }

  > label {
    font-family: ${({ theme }) => theme.secondaryFont};
  }
`;

const Divider = styled.hr`
  margin: 2rem 0;
  border: 1px solid ${({ theme }) => theme.lightGray};
`;

const SupportView = (): React.ReactElement => {
  const { showSupportPhone } = useContext(userContext);
  return (
    <div style={{ marginBottom: '3rem' }}>
      <ContentWrapper>
        <h2>Contact Us</h2>
        <ResponsiveRow>
          {showSupportPhone && (
            <Row>
              <label>Phone:</label>
              <span>{TPCPhone}</span>
            </Row>
          )}
          <Row>
            <label>Email:</label>
            <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
          </Row>
        </ResponsiveRow>
        <Row>
          <Divider />
          <Link to="/passwordreset">Forgot your password?</Link>
        </Row>
      </ContentWrapper>
    </div>
  );
};

export default SupportView;
