import { useState } from 'react';
import styled from 'styled-components';
import { compress } from 'utils/photoUpload';
import api from 'utils/api';
import { Theme } from 'shared/_types';
import DriveSide from 'icons/DriveSide';
import NonDriveSide from 'icons/NonDriveSide';
import AdditionalUpload from 'icons/AdditionalUpload';
import closeX from 'images/close-icon.svg';
import ProgressBar from './ProgressBar';
import { CircularProgress } from '@material-ui/core';

interface ChangeOrDragEvent {
  target: HTMLInputElement | EventTarget;
}

const iconSrcMap = {
  driveSide: DriveSide,
  nonDriveSide: NonDriveSide,
  additional: AdditionalUpload,
};
type PhotoUploadTypes = keyof typeof iconSrcMap;
interface Props {
  alt: string;
  type: PhotoUploadTypes;
  dealer: boolean;
  id: string;
  photoUrl: string;
  required?: boolean;
  clearImage: () => void;
  onUploadSuccess: (url: string) => void;
}

const FileInput = styled.input`
  cursor: inherit; // Firefox doesn't inherit correctly otherwise
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const Dropzone = styled.div<{ theme: Theme; type?: PhotoUploadTypes; dealer: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: ${({ dealer }) => (dealer ? '0.75px solid #929497' : '1px solid black')};
  position: relative;
  height: ${({ type }) =>
    type === 'additional'
      ? '90px'
      : '142px'}; // these values form ~perfect squares at our 375px min display width
  overflow: hidden;
  background-color: white;

  &:hover {
    border-color: ${({ theme }) => theme.primary};
    transition: border-color 0.2s;
  }

  > ${FileInput} {
    height: 100%;
  }
`;

const CloseButton = styled.button`
  cursor: inherit;
  z-index: 10;
  padding: 0.25rem;
  background-color: white;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

interface SignedURLResponse {
  signedRequest: string;
  url: string;
}

const ImageUpload = (props: Props): React.ReactElement => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<Error | null>(null);
  const [inputKey, setInputKey] = useState(0);
  const { onUploadSuccess } = props;

  const makeS3Request = (signedUrl: string, file: Blob | null) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;
        if (xhr.status === 200) return resolve(true);
        console.error('error: could not upload image', xhr.status, xhr.statusText);
        return reject(new Error('error: could not upload image'));
      };

      xhr.upload.onprogress = ({ loaded, total }) => setProgress((loaded / total) * 100);

      xhr.open('PUT', signedUrl);
      xhr.send(file);
    });

  const handleDrag = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = async (e: ChangeOrDragEvent) => {
    const evtTarget = e.target as HTMLInputElement;
    const { files } = evtTarget;

    if (!files?.[0]) return console.error('error: no files were uploaded');

    setError(null);
    setProgress(10);

    const file: File = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    // todo: API logic to switch which S3 bucket this goes to based on partner ID? or different URL/bucketname env vars per client?
    const signedUrlUrl = `/partners/signedUrl?file-type=${file.type}`;

    try {
      const result = await api.get<SignedURLResponse>(signedUrlUrl);
      const compressedFile = await compress(file, 100);
      if (compressedFile) {
        const { signedRequest, url } = result;
        await makeS3Request(signedRequest, compressedFile);
        return onUploadSuccess(url);
      } else {
        throw new Error('could not compress file for upload');
      }
    } catch (error: any) {
      setError(error);
    }
  };

  const isProgressComplete = progress === 100;
  const isLoading = progress > 0 && !isProgressComplete;

  const IconComponent = iconSrcMap[props.type];

  return (
    <Dropzone type={props.type} dealer={props.dealer}>
      <LoaderWrapper>{isLoading && <CircularProgress size={20} />}</LoaderWrapper>
      {isProgressComplete && (
        <CloseButton
          onClick={() => {
            setProgress(0);
            props.clearImage();
            setError(null);
            setInputKey(inputKey + 1); // force input value to clear without making the input controlled
          }}
        >
          <img src={closeX} alt="clear uploaded file" />
        </CloseButton>
      )}
      <FileInput
        key={inputKey}
        onDrop={handleDrop}
        onChange={handleDrop}
        onDragOver={handleDrag}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        type="file"
        multiple={false}
        accept="image/jpeg"
        id={props.id}
        required={props.required ?? false}
      />
      {props.photoUrl && isProgressComplete ? (
        <img
          src={props.photoUrl}
          alt={props.alt}
          style={{ height: '100%', width: '100%', objectFit: 'contain' }}
        />
      ) : (
        <IconComponent style={{ cursor: 'inherit', height: '100px' }} />
      )}
      {error && <span style={{ margin: '0 auto', color: 'red' }}>{error.message}</span>}
      <ProgressBar progress={progress} />
    </Dropzone>
  );
};

export default ImageUpload;
